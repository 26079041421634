import React, { useCallback, useMemo, useState } from "react"
import styles from "./YouTube.module.scss"
import cn from "classnames"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Image from "next/image"
import youtubePlayImg from "@public/images/youtube_play.png"

function YouTubeGetID(url) {
    let ID
    url = url.replace(/([><])/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i)
        ID = ID[0]
    } else {
        ID = url
    }
    return ID
}

export function YouTube({ url, alt = "YouTube video", className, videoClassName, width = "30px", height = "30px", iframeHeight }) {
    const [play, setPlay] = useState(false)
    const id = useMemo(() => YouTubeGetID(url), [url])

    const onPlay = useCallback(() => {
        setPlay(true)
    }, [setPlay])

    let videoContent

    if (play) {
        videoContent = (
            <iframe
                loading="lazy"
                height={iframeHeight}
                className={cn(videoClassName, styles.video)}
                src={`https://www.youtube.com/embed/${id}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        )
    } else {
        videoContent = (
            <>
                <LazyLoadImage src={`https://img.youtube.com/vi/${id}/0.jpg`} alt={alt} className={styles.videoImg} onClick={onPlay} width={width} height={height} />
                <button className={styles.playBtn} aria-label="Play" onClick={onPlay}>
                    <Image loading="lazy" src={youtubePlayImg} alt={alt} onClick={onPlay} width={148} height={120} />
                </button>
            </>
        )
    }

    return <div className={cn(styles.root, className)}>{videoContent}</div>
}
