import { isBrowser } from "@utils/const"
import { prepareDataMultiple } from "../MultipleResortChart/utils"
import { addCurrency } from "@utils/index"
import moment from "moment"
import Image from "next/image"
import starImg from "@public/star.svg"

const skipped = (ctx, value) => {
    if (ctx.p0.skip || ctx.p1.skip) {
        return value
    }
    return undefined
}
export const segment = {
    borderDash: (ctx) => skipped(ctx, [0, 0])
}

export const groups = {
    low1: {
        from: 1,
        to: 75
    },
    low: {
        from: 76,
        to: 125
    },
    middle1: {
        from: 126,
        to: 199
    },
    middle: {
        from: 200,
        to: 349
    },
    high: {
        from: 350,
        to: 5000
    }
}

export function findGroup(points) {
    const key = Object.keys(groups).find((i) => {
        const value = groups[i]
        return points <= value.to && points >= value.from
    })
    return groups[key]
}

function groupAvr(i, points) {
    const list = []
    const poc = i.points_on_contract.split(",")
    const pp = i.price_per_point.split(",")
    poc.forEach((p, i) => {
        list.push({
            points_on_contract: Number(p),
            price_per_point: Number(pp[i])
        })
    })
    let group = findGroup(points)
    const { from, to } = group
    const newList = list.filter((i) => i.points_on_contract >= from && i.points_on_contract <= to)
    const sum = newList.reduce((r, i) => {
        return r + i.price_per_point
    }, 0)
    const res = (sum || 1) / (newList.length || 1)
    if (res === 1) {
        return null
    }
    return res
}

export function getAvr(i, points_on_contract) {
    if (i.price_per_point) {
        return groupAvr(i, points_on_contract)
    } else {
        return i.averagePP ? i.averagePP : i.averagePrice / points_on_contract
    }
}

function mergeDatasets(resortDataset, labels, extraData) {
    // Step 1: Collect all dates into a Set to ensure uniqueness
    const dateSet = new Set([...labels, ...extraData.map((item) => item.date)])
    const allDates = Array.from(dateSet).sort((a, b) => a - b) // Sort dates in ascending order

    // Step 2: Create new arrays to hold the merged data
    const mergedLabels = []
    const mergedResortDataset = []
    const mergedResortDataset2 = []

    // Step 3: Iterate through the sorted dates and populate the arrays
    allDates.forEach((date) => {
        mergedLabels.push(date)

        // Check if date exists in the original labels to find matching data in resortDataset
        const indexInOriginal = labels.findIndex((d) => d.isSame(date, "day"))
        mergedResortDataset.push(indexInOriginal !== -1 ? resortDataset[indexInOriginal] : null)

        // Find matching price in extraData for the date or add null if not found
        const extraDataMatchIndex = extraData.findIndex((item) => item.date.isSame(date, "day"))
        const extraDataMatch = extraData[extraDataMatchIndex]
        mergedResortDataset2.push(extraDataMatch ? extraDataMatch.price : null)
    })

    return {
        labels: mergedLabels,
        resortDataset: mergedResortDataset,
        resortDataset2: mergedResortDataset2
    }
}

function fillNullWithPreviousValue(arr) {
    arr.forEach((i, index, array) => {
        if (i === null && array[index - 1] !== null) {
            array[index] = array[index - 1]
        }
    })
}

export function prepareData(statistic, listing, mobile) {
    if (!isBrowser) return {}
    const limit = mobile ? -6 : -20
    const { price_per_point, points_on_contract } = listing
    const { datasets } = prepareDataMultiple(
        {
            [listing.resort.id]: {
                data: statistic,
                resort: listing.resort.id
            }
        },
        false,
        points_on_contract
    )
    const result = mergeDatasets(
        datasets[0].data,
        Array.from(datasets[0].groupLabels).map(([_, { rawDate }]) => {
            return moment(rawDate)
        }),
        Object.values(
            listing.price_updates.reduce((result, i) => {
                const key = moment(i.date).endOf("day").valueOf()
                result[key] = {
                    date: moment(i.date),
                    price: i.price / listing.points_on_contract
                }
                return result
            }, {})
        )
    )
    const weekBefore = moment().subtract(14, "days")
    const { resortDataset, resortDataset2, labels } = result

    fillNullWithPreviousValue(resortDataset)
    fillNullWithPreviousValue(resortDataset2)
    const newLabels = labels.map((i) => (weekBefore.isBefore(moment(i)) ? i.fromNow() : i.format("MMMM YY")))
    const dataCount = newLabels.length
    const current = new Array(dataCount).fill(null)
    current[current.length - 1] = price_per_point

    /**
     *
     * @type {{datasets: import("chart.js").ChartDataset[], labels: string[]}}
     */
    const res = {
        labels: newLabels.slice(limit),
        datasets: []
    }

    const StarComponent = () => <Image src={starImgSrc} width={20} height={20} alt="Star" />
    const thisContractData = resortDataset2.slice(limit)
    const hasMoreThenOnePoint = thisContractData.filter((i) => i !== undefined).length > 1
    res.datasets.push({
        spanGaps: true,
        segment,
        enabled: true,
        label: "This contract ",
        datalabels: {
            color: "#00FFD1",
            anchor: "right",
            align: "top",
            display: (ctx) => {
                return ctx.dataIndex % 2
            },
            formatter: (value) => {
                return addCurrency(Math.round(value))
            },
            labels: {
                another: {
                    offset: 10,
                    anchor: "center",
                    align: "top",
                    formatter: (value, rest) => {
                        if (hasMoreThenOnePoint) {
                            return addCurrency(value)
                        }
                        return null
                    }
                },
                that: {
                    offset: 10,
                    anchor: "center",
                    align: "left",
                    formatter: (value) => {
                        if (!hasMoreThenOnePoint) {
                            return "This contract: " + addCurrency(value)
                        }
                        return null
                    }
                }
            }
        },
        data: thisContractData,
        borderColor: "#00FFD1",
        backgroundColor: "#00FFD1",
        pointStyle: (ctx) => {
            if (ctx.dataset.data.length - 1 === ctx.dataIndex) {
                return StarComponent
            }
            return "circle"
        },
        tension: 0.4,
        pointRadius: 7,
        borderWidth: 2,
        pointBorderWidth: 1.75,
        pointBorderColor: "#30254C",
        pointHoverRadius: 10
    })
    res.datasets.push({
        spanGaps: true,
        segment,
        enabled: true,
        label: "Avg resale price",
        data: resortDataset.slice(limit),
        datalabels: {
            anchor: "right",
            align: "top",
            display: (ctx) => {
                return ctx.dataIndex % 2
            },
            formatter: (value) => {
                return addCurrency(Math.round(value))
            },
            color: "#FF7E7E"
        },
        borderColor: "#FF7E7E",
        backgroundColor: "#FF7E7E",
        pointStyle: "circle",
        tension: 0.4,
        pointRadius: 7,
        borderWidth: 2,
        pointBorderWidth: 1.75,
        pointBorderColor: "#30254C",
        pointHoverRadius: 10
    })
    return res
}
